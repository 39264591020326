// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import ReactHTMLParser from 'react-html-parser';
import { ALIGN, ALIGN_ITEMS, BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import Section from '@latitude/section';
import data from '../../data/pages/become-a-partner.json';
import ReactPlayer from 'react-player/lazy';



type PromoProps = {
    title: React.Node | string,
    description?: string,
    frameAlignment?: string,
    frameImage?: React.Node | string,
    frameBackgroundColor?: string,
    frameBorderColor?: string,
    verticalAlignContent: boolean,
    ctaButtons?: React.Node,
    listData:Array
};

const List = styled.ol`
  list-style-type: none;
  margin: 0;
`;

const ListItem = styled.li`
counter-increment: list-num;
  display:flex;
  min-height: 70px;
  font-weight: 300;
  margin: 0;
  padding: 10px 0 10px 16px;
  line-height: 22px;
  color: ${COLOR.BODY};
  text-align: left;

  :first-child {
    ${({ separator }: Props) => {
        if (separator !== 'light') {
            return '';
        }

        return `border-top-style: none;`;
    }}
  }

  :nth-last-child(1) {
    ${({ separator }: Props) =>
        separator === 'standard' ? `border-bottom: 1px solid #979797;` : ''};
  }

  :before {
    content: counter(list-num);
    display: block;
    position: relative;
    left: -16px;
    height: 30px;
    width: 30px;
    min-width: 30px;
    background: ${COLOR.BLUE};
    border-radius: 100%;
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    color: ${COLOR.WHITE};
  }
`;

const PromoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINT.LG}) {
    display:flex;
    flex-direction: row;
    padding: 0px;
    justify-content: ${(PromoProps) => (PromoProps.frameVideoImage ? 'space-between' : 'center')};
  }
`;

const PromoContentContainer = styled.div`
  padding: 24px 0;
  text-align: center;

  ${({ verticalAlign }: PromoProps) =>
        verticalAlign &&
        css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 1 0%; /* using % for flex-basis as workaround to Safari and IE11 flex bugs */
    `} h3 {
    font-size: 32px;
    line-height: 1.13;
    margin-bottom: 16px;
    color: ${COLOR.BLACK};
    text-align: center;
    justify-content: space-between;

    @media (min-width: ${BREAKPOINT.LG}) {
      font-size: 40px;
      line-height: 1.2;
      text-align: ${({ alignment }: PromoProps) =>
        alignment === 'left' ? 'left' : 'right'};
    }
  }

  p {
    color: ${COLOR.GREY75};
    text-align: center;

    @media (min-width: ${BREAKPOINT.SM}) {
      max-width: 320px;
      margin: 0 auto;
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      margin: 0;
      max-width: 420px;
      text-align: ${({ alignment }: PromoProps) =>
        alignment === 'left' ? 'left' : 'right'};
    }
  }

  a{
    text-decoration:underline;
    text-underline-position: under;
    text-decoration-thickness: from-font;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    text-align: left;
    order: ${({ alignment }: PromoProps) => (alignment === 'left' ? 1 : 2)};
  }
`;



/*
 * VIDEO PROMO styles - These are different to cater to new design needs.
 * TODO: Style other variants of the Promo component to match this styling.
 */
const VideoPromoFrameContainer = styled.div`
  ${({ bgColor }: PromoProps) =>
        bgColor &&
        css`
      background-color: ${bgColor};
    `};
  position: relative;
  width: 300px;
  height: auto;
  max-width: 100%;
  margin: 0 auto 45px auto;
  ${({ alignment }: PromoProps) =>
        alignment === 'left'
            ? css`
          transform: translateX(15px);
        `
            : css`
          transform: translateX(-15px);
        `};

  @media (min-width: ${BREAKPOINT.LG}) {
    width: 393px;
    height: 221px;
    transform: none;
    margin: ${({ alignment }: PromoProps) =>
        alignment === 'left' ? '10% 0 45px 45px' : '10% 45px 45px 0'};
    order: ${({ alignment }: PromoProps) =>
        alignment === 'left' ? 1 : 2};
  }

  &:before {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 30px;

    ${({ alignment }: PromoProps) =>
        alignment === 'left'
            ? css`
            left: -30px;
          `
            : css`
            right: -30px;
          `};
    border: solid 20px ${({ borderColor }: PromoProps) => borderColor};
    z-index: -1;

    @media (min-width: ${BREAKPOINT.LG}) {
      top: 30px;
      ${({ alignment }: PromoProps) =>
        alignment === 'left'
            ? css`
              left: -30px;
            `
            : css`
              right: -30px;
            `};
      border: solid 20px ${({ borderColor }: PromoProps) => borderColor};
      z-index: auto;
    }
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */

  .react-player {
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 64px;
      height: 64px;

      @media (min-width: ${BREAKPOINT.LG}) {
        width: 96px;
        height: 96px;
      }
    }
  }
`;

const PromoListBox = ({
    counterBgColor = COLOR.BLUE,
    frameImage,
    frameVideoImage,
    description,
    title,
    frameAlignment,
    frameBorderColor,
    frameBackgroundColor,
    verticalAlignContent,
    frameVideoSrc,
    listData
}: PromoProps) => (
    <PromoContainer>
        <PromoContentContainer
            verticalAlign={verticalAlignContent}
            alignment={frameAlignment}
            css={`
            @media (min-width: ${BREAKPOINT.LG}) {
                width:60%;
                
        `}
        >
            <List>
                {listData.map((item, i) => (
                    <ListItem key={i}>
                        <div
                            css={`
                         @media (min-width: ${BREAKPOINT.LG}) {
                             width:80%;
                            
                     `}>
                            <div css={`text-align:left;`}><strong>{ReactHTMLParser(item.title)}</strong></div>
                            <div css={`
            @media (min-width: ${BREAKPOINT.LG}) {
                margin-top:10px;
                
        `}>{ReactHTMLParser(item.value)}</div>
                        </div>
                    </ListItem>
                ))}
            </List>
        </PromoContentContainer>

        {frameVideoImage && (
            <VideoPromoFrameContainer
                alignment={frameAlignment}
                borderColor={frameBorderColor}
                bgColor={frameBackgroundColor}
            >
                <PlayerWrapper>
                    <ReactPlayer
                        url={frameVideoSrc}
                        className="react-player"
                        width="100%"
                        height="100%"
                        light={frameVideoImage}

                    />
                </PlayerWrapper>
            </VideoPromoFrameContainer>
        )}
    </PromoContainer>
);


export const PromoSection = styled(Section)`
  && {
    z-index: 0;
    .section__content {
      max-width: 1110px;
    }
  }
`;

export default PromoListBox;
